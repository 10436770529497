<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		form(@submit.prevent="submit", v-if="offer")
			.row
				.col-md-9
					b-tabs(content-class="mt-3", pills, card)
						b-tab(title="Main", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label General Information <span v-if="offer.id">({{ offer.id }})</span>
										.card-body
											.row
												.col-sm-4
													.form-group
														label ID
														input.form-control.readonly(type="text", :value="offer.id", readonly="readonly")
												.col-sm-4
													.form-group
														label Advertiser
														input.form-control.readonly(type="text", :value="advertiser.name", readonly="readonly")
												.col-sm-4
													.form-group
														label Adv. Offer ID
														input.form-control.readonly(type="text", :value="offer.externalOffer ? offer.externalOffer.external_id : ''", readonly="readonly")
											.form-group
												label Name
												input.form-control(type="text", v-model="offer.name")
											.row
												.col-sm-4
													.form-group
														label Status
														select.form-control(v-model="offer.status")
															option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
												.col-sm-4
													.form-group
														label E.Status
														select.form-control(v-model="offer.effective_status")
															option(v-for="o in offerEffectiveStatusOptions", :value="o.v") {{ o.t }}
												.col-sm-4
													.form-group
														label Type
														select.form-control(v-model="offer.type")
															option(v-for="o in offerTypeOptions", :value="o.v") {{ o.t }}
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label General Info
										.card-body
											.form-group
												label Campaign Description
												textarea.form-control(placeholder="Campaign description", v-model="offer.description", rows="5")
											.row
												.col-md-6
													.form-group
														label KPI Description
														textarea.form-control(placeholder="KPI", v-model="offer.kpi_description")
												.col-md-6
													.form-group
														b-form-checkbox(v-model="offer.require_device_id", switch) Require Device ID
														b-form-checkbox(v-model="offer.require_appname", switch) Require AppName
														b-form-checkbox(v-model="offer.require_bundle", switch) Require BundleID
														b-form-checkbox(v-model="offer.incent", switch) Allow Incent

											.form-group
												label Preview URL
													=' '
													a(href="javascript:void(0);", @click="loadAssetInfo()")
														i.la.la-refresh
												input.form-control(type="text", placeholder="Preview URL", v-model="offer.preview_url")
											.form-group
												label Asset
												select.form-control(v-model="offer.asset_id")
													option(v-for="o in assetOptions", :value="o.v") {{ o.t }}

								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Payouts
										.card-body
											.row
												.col-xl-4.col-lg-6
													.form-group
														label.d-flex
															span(style="flex: 1") Payout
															span.text-muted Currency
														.input-group
															input.form-control(type="text", v-model="offer.payout")
															select.form-control.text-muted(v-model="offer.currency")
																option(v-for="o in currencyOptions", :value="o.v") {{ o.t }}
														//div(v-if="offer.externalOffer", style="margin-top: 10px;")
															label.d-flex
																span(style="flex: 1") Advertiser Payout
																span.text-muted
																	a(href="javascript:void(0);", @click="copyPayout()") Set payout
															.input-group
																input.form-control(type="text", :value="offer.externalOffer.payout")
																input.form-control.text-muted(v-model="copyPayoutPercent")

												.col-xl-4.col-lg-6
													.form-group
														label Model
														select.form-control(v-model="offer.payout_model")
															option(v-for="o in payoutModelOptions", :value="o.v") {{ o.v }}
											hr
											h5 Events
											table.table.table-striped
												thead
													tr
														th Name
														th Label
														th Payout
														th(width="50")
															button.btn.btn-sm.btn-circle.btn-primary(type="button", @click="addEvent()")
																i.la.la-plus
												tbody
													tr(v-for="(e, i) in offer.events")
														td
															input.form-control(type="text", v-model="e.name")
														td
															input.form-control(type="text", v-model="e.label")
														td
															input.form-control(type="text", v-model="e.payout")
														td
															button.btn.btn-sm.btn-circle.btn-default(type="button", @click="offer.events.splice(i, 1)")
																i.la.la-minus


									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Daily Caps
										.card-body
											.row
												.col-xl-3.col-lg-6
													.form-group
														label Conversion Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_conversion_cap")
												.col-xl-4.col-lg-6
													.form-group
														label Click Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_click_cap")
												.col-xl-4.col-lg-6
													.form-group
														label Impression Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_impression_cap")



						b-tab(title="Tracking Link", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										//.card-header
											.card-title
												.card-label Tracking Links
										.card-body
											.row
												.col-sm-6
													.form-group.no-label
														b-form-checkbox(v-model="offer.is_vta", switch, inline) Is VTA?
													.form-group.no-label
														b-form-checkbox(v-model="offer.scan_required", switch, inline) Scan required
													.form-group.no-label
														b-form-checkbox(v-model="offer.direct_link", switch, inline) Is direct link? (MMP / Asset Link)
													//.form-group.no-label
														b-form-checkbox(v-model="offer.sign_clicks", switch, inline) Sign Clicks
												.col-sm-6
													.form-group
														label MMP
														.input-group
															select.form-control(v-model="offer.mmp")
																option(:value="null") - None -
																option(v-for="o in mmpOptions", :value="o.v") {{ o.t }}
											.form-group
												//label Click URL
												//textarea.form-control(v-model="offer.click_url", rows="3")
												url-input(v-model="offer.click_url", label="Click URL")
											.form-group
												//label Impression URL
												url-input(v-model="offer.impression_url", label="Impression URL")
												//textarea.form-control(v-model="offer.impression_url", rows="3")
											.form-group
												label AdvSub1 postback parameter
												.input-group
													input.form-control(type="text", v-model="offer.adv_sub1_postback")

						b-tab(title="Targeting", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Informative (Non-restrictive) definitions
										.card-body
											.form-group
												label Countries
												textarea.form-control(rows="3", placeholder="GB, US, ...", v-model="offer.countries")
											.form-group
												label Platform
												select.form-control(v-model="offer.platform")
													option(v-for="o in platformOptions", :value="o.v") {{ o.t }}

								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label GEO Restrictions
										.card-body
											h3.widget-section-title Geo Whitelist
											.form-group
												label Countries
												multiselect(:multiple="true", :options="countryOptions", v-model="targeting.geo.whitelist",
													track-by="code2", label="label", deselect-label="", select-label="", @input="targetingOnChangeCountryWL()")

											div.targeting-country-block(v-for="cn in targeting.geo.whitelist")
												h4 [{{cn.code2}}] {{ cn.name }}
												.form-group(v-if="regionOptions[cn.code2]")
													label Regions / States
													multiselect(:multiple="true", :options="regionOptions[cn.code2]", v-model="cn.regions",
														track-by="id", label="label", deselect-label="", select-label="")
												.form-group(v-if="cityOptions[cn.code2]")
													label Cities
													multiselect(:multiple="true", :options="cityOptions[cn.code2]", v-model="cn.cities",
														track-by="id", label="label", deselect-label="", select-label="")
											//pre {{ offer.targeting_hash }}
							.card.card-custom.gutter-b
								.card-header
									.card-title
										.card-label Geo Params
								.card-body
									table.table
										thead
											tr
												th(style="width: 150px;") Countries
												th Params
												th(width="50")
													button.btn.btn-sm.btn-circle.btn-primary(type="button", @click="addGeoParam()")
														i.la.la-plus
										tbody
											tr(v-for="(pr, i) in offer.geo_params")
												td
													input.form-control.form-control-solid(type="text", v-model="pr.country")
												td
													input.form-control.form-control-solid(type="text", v-model="pr.params")
												td
													button.btn.btn-sm.btn-circle.btn-default(type="button", @click="offer.geo_params.splice(i, 1)")
														i.la.la-minus

						b-tab(title="Data Control", style="padding: 0")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Subsource Caps / Limits
										.card-body
											p.text-muted These reflect on the advertiser side. It is recommended to leave out subsource caps on complex offers.
											.row
												.col-xl-4.col-lg-6
													.form-group
														label Subsource Click Cap
														.input-group
															input.form-control(type="text", v-model="offer.daily_subsource_click_cap")
												.col-xl-4.col-lg-6
													.form-group
														label Daily Subsource Limit
														.input-group
															input.form-control(type="text", v-model="offer.daily_subsource_limit")
												.col-xl-4.col-lg-6
													.form-group
														label Total Subsource Limit
														.input-group
															input.form-control(type="text", v-model="offer.total_subsource_limit")

								.col-md-6
									.card.card-custom.gutter-b
										.card-header
											.card-title
												.card-label Override
										.card-body
											.row
												.col-sm-6
													.form-group
														label Data Control Template
														select.form-control(v-model="offer.data_control_id")
															option(value="") None
															option(v-for="o in dataControlOptions", :value="o.v") {{ o.t }}
												.col-sm-6
													.form-group
														label Override Attr. Window
														input.form-control(type="text", v-model="offer.override_attribution_window")

											.row
												.col-sm-6
													.form-group
														label Subsource Mode
														select.form-control(v-model="offer.sub1_mode")
															option(:value="null") None
															option(:value="'override'") Override
															option(:value="'override_empty'") Override Empty
															option(:value="'block_empty'") Block Empty
												.col-sm-6
													.form-group
														label Asset Mode
														select.form-control(v-model="offer.asset_mode")
															option(:value="null") None
															option(:value="'override'") Override
															option(:value="'override_empty'") Override Empty
															option(:value="'block_empty'") Block Empty

						b-tab(title="Connected Offers", style="padding: 0", :disabled="!isComplex")
							.row
								.col-md-6
									.card.card-custom.gutter-b
										.card-body
											table.table
												thead
													tr
														th(style="width: 40px;")
															button.btn-secondary.btn-secondary.btn-circle(type="button", @click="addConnectedOffer()")
																i.la.la-plus
														th Offer
														th(style="width: 80px;") Weight
												tbody
													tr(v-for="(o, i) in offer.subOffers")
														td.text-center
															b-form-checkbox(v-model="o.enabled", switch)
														td
															// select.form-control(v-model="o.offer_id")
																option(v-for="op in offerOptions", :value="op.v") {{ op.t }}
															v-select(:options="offerOptions", :getOptionLabel="op => op.t", v-model="o.child_id", :reduce="op => op.v")
																template(#option="{ v, t }") {{ t }}
														td
															input.form-control(type="text", v-model="o.weight")
														td
															button.btn.btn-sm.btn-secondary(type="button", @click="offer.subOffers.splice(i,1)")
																i.la.la-times
									.form-group
										label Geo Block mode
										select.form-control(v-model="offer.geo_block_mode")
											option(v-for="o in geoBlockOptions", :value="o.v") {{ o.t }}

				.col-sm-3
					.card.card-custom.gutter-b
						.card-header
							.card-title
								.card-label Summary
						.card-body
							dl
								dt Name
								dd {{ offer.name }}

								dt Platform, Geo
								dd {{ offer.platform }}, {{ offer.countries }}

								dt MMP
								dd {{ offer.mmp }}

								dt Remote Offer ID
								dd {{ offer.externalOffer ? offer.externalOffer.external_id : '' }}



</template>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import store from "@/core/services/store";
import {OVERRIDE_PAGE_LAYOUT_CONFIG, SET_LAYOUT_CONFIG} from "@/core/services/store/config.module";
import HtmlClass from "@/core/services/htmlclass.service";


async function loadRoute(vm, to, from, next){

	let advertiserId = +to.query.advertiser_id;
	let offer = {};
	if (to.name === 'offer-edit') {
		let entityId = +to.params.id;
		try {
			offer = await Vue.$dwData.offer.get(entityId, true);
		} catch (e) {
			Vue.$notify.error('Failed to load offer');
			return next(false);
		}
	} else {

		if (+to.query.copy_offer_id){
			let offerId = +to.query.copy_offer_id;
			offer = await Vue.$dwData.offer.get(offerId, true);
			offer.id = 0;
		} else {

			let externalOfferId = +to.query.eo_id;
			if (externalOfferId) {
				offer = await Vue.$dwData.offer.prepareFromExternal(externalOfferId);
			} else {
				offer = Vue.$dwData.offer.newInstance();
				offer.advertiser_id = advertiserId;
			}
		}
	}
	let advertiser = null;
	if (offer.advertiser_id){
		advertiser = await Vue.$dwData.advertiser.get(offer.advertiser_id);
	}
	// Vue.ovData.advertiser._booleans.forEach(f => {
	// 	advertiser[f] = !!advertiser[f];
	// });

	let assetOptions = await Vue.$dwData.asset.getOptions(true);
	let offerOptions = await Vue.$dwData.offer.getOptions(true);
	let dataControlOptions = await Vue.$dwData.dataControl.getOptions(true);


	let countryOptions = await Vue.$dwData.country.getOptions();

	return vm => {
		// vm.publisherOptions = publisherOptions;
		// vm.integrationTypeOptions = integrations;
		vm.countryOptions = countryOptions;
		vm.dataControlOptions = dataControlOptions;
		vm.offerOptions = offerOptions;
		vm.assetOptions = assetOptions;
		vm.advertiser = advertiser;
		vm.offer = offer;
		let targeting = offer.targeting;
		if (!targeting){
			targeting = {};
		}
		if (!targeting.geo) {
			targeting.geo = {whitelist: [], blacklist: []};
		}
		vm.targeting = targeting;
		vm.targetingOnChangeCountryWL();
		// vm.targetingOnChangeCountryBL();
		vm.offer.effective_status = null;
		vm.busy = false;
		return vm;
	};
}

export default {
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	name: 'OfferForm',
	computed: {
		isComplex(){
			return this.offer.type === 'asset' || this.offer.type === 'custom';
		}
	},
	data(){
		return {
			busy: true,
			offer: null,
			countryOptions: [],
			regionOptions: {},
			cityOptions: {},
			offerTypeOptions: Vue.$dwData.general.offerTypeOptions,
			offerStatusOptions: Vue.$dwData.general.offerStatusOptions,
			offerEffectiveStatusOptions: [
				{v: null, t: '- Auto -'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
			],
			currencyOptions: Vue.$dwData.general.currencyOptions,
			platformOptions: Vue.$dwData.general.platformOptions,
			timeZoneOptions: Vue.$dwData.general.timezoneOptions,
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
			mmpOptions: Vue.$dwData.general.mmpOptions,
			copyPayoutPercent: 70,
			assetOptions: [],
			offerOptions: [],
			dataControlOptions: [],
			targeting: null,
			geoBlockOptions: [
				{v: 'block', t: 'Block mismatch'},
				{v: 'prioritize', t:'Prefer matching offer'}
			]
		};
	},
	methods: {
		addGeoParam(){
			this.offer.geo_params.push({
				country: '',
				params: ''
			});
		},
		addEvent(){
			this.offer.events.push({
				name: '',
				payout: 0,
			});
		},

		addConnectedOffer(){
			this.offer.subOffers.push({
				child_id: null,
				weight: 0,
				enabled: false
			});
		},
		copyPayout(){
			this.offer.payout = +(((+this.copyPayoutPercent/100) * this.offer.externalOffer.payout).toFixed(2));
		},

		async targetingOnChangeCountryWL(){
			for (let i = 0; i < this.targeting.geo.whitelist.length; i++) {
				let cn = this.targeting.geo.whitelist[i];
				if (!cn.regions) {
					Vue.set(cn, 'regions', []);
				}
				if (!cn.cities) {
					Vue.set(cn, 'cities', []);
				}
				console.log('cn.code', cn.code2);
				if (!this.regionOptions[cn.code2]) {
					Vue.set(this.regionOptions, cn.code2, await this.$dwData.region.getOptions(true, cn.code2));
				}
				if (!this.cityOptions[cn.code2]) {
					Vue.set(this.cityOptions, cn.code2, await this.$dwData.city.getOptions(true, cn.code2));
				}
			}
		},

		async targetingOnChangeCountryBL(){
			for (let i = 0; i < this.targeting.countries.blacklist.length; i++) {
				let cn = this.targeting.countries.blacklist[i];
				if (!cn.regions) {
					Vue.set(cn, 'regions', []);
				}
				if (!cn.cities) {
					Vue.set(cn, 'cities', []);
				}
				if (!this.regionOptions[cn.code2]) {
					Vue.set(this.regionOptions, cn.code2, await this.$ovData.region.getOptions(true, cn.code2));
				}
				if (!this.cityOptions[cn.code2]) {
					Vue.set(this.cityOptions, cn.code2, await this.$ovData.city.getOptions(true, cn.code2));
				}
			}
		},

		async loadAssetInfo(){
			if (this.busy){
				return;
			}
			if (!this.offer.preview_url){
				return;
			}
			this.busy = true;
			try {
				let data = {
					url: this.offer.preview_url
				};
				let resp = await this.$api.post('assets/fetch', data);
				console.log('RESP', resp);
				if (resp.asset_id){
					this.offer.asset_id = resp.asset_id;
					if (resp.is_new){
						this.assetOptions = await Vue.$dwData.asset.getOptions(true);
					}
				}
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		},
		async submit(closeAfterSave = false){
			if (this.busy){
				return;
			}
			this.busy = true;
			let data = Vue.util.extend({}, this.offer);
			try {
				let resp = await this.$api.post('offers/save', data);
				this.busy = false;
				this.$notify.success('Offer has been saved');
				if (closeAfterSave) {
					this.$router.push({name: 'offer-list'});
				} else {
					this.offer = resp.entity;
				}
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		}
	},
	mounted(){
		let vm = this;
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Offers', name: 'offer-list'},
			{title: this.offer && this.offer.id ? 'Edit Offer' : 'Add Offer'}
		]);
		this.$store.dispatch('setBackButton', {
			route: {name: 'offer-list'}
		});
		this.$store.dispatch('setPageButtons', [
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save',
				action(){
					vm.submit();
				}
			},
			{
				side: 'right',
				class: 'btn-primary',
				label: 'Save & Close',
				action(){
					vm.submit(true);
				}
			}
		]);
	},
}

</script>
